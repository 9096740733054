// extracted by mini-css-extract-plugin
export var account = "Account-module--account--1tm6C";
export var title = "Account-module--title--1zFhW";
export var labelpassword = "Account-module--labelpassword--zoD3M";
export var labeltext = "Account-module--labeltext--V5a3Y";
export var inputStyle = "Account-module--inputStyle--1oagQ";
export var inputStyleNew = "Account-module--inputStyleNew--vjdwk";
export var passwordCriteriaView = "Account-module--passwordCriteriaView--16CvA";
export var section = "Account-module--section--1BiRe";
export var userProfile = "Account-module--userProfile--4Fg53";
export var profilePicLoader = "Account-module--profilePicLoader--2enW2";
export var profilePic = "Account-module--profilePic--3jRnu";
export var editableField = "Account-module--editableField--1NP9U";
export var editName = "Account-module--editName--1uha-";
export var error = "Account-module--error--1OB-h";
export var ctaSection = "Account-module--ctaSection--1REjA";
export var button = "Account-module--button--3ntiB";
export var buttonSave = "Account-module--buttonSave--2cSE3";
export var buttonCancel = "Account-module--buttonCancel--1A5hU";
export var fieldGroup = "Account-module--fieldGroup--1Aguq";
export var fieldTitleSection = "Account-module--fieldTitleSection--1uhhk";
export var fieldTitle = "Account-module--fieldTitle--3ZiUy";
export var fieldValue = "Account-module--fieldValue--34Ssh";
export var logoutCta = "Account-module--logoutCta--3qwaO";
import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { Storage } from 'aws-amplify';
import dayjs from 'dayjs';
import * as styles from '../../styles/learner/Account.module.scss';
import LearnerContext from '../../context/Learner/LearnerContext';
import {
  getCurrentUser,
  logout, updateName, updatePassword, updateUserImage, validatePasswordFields,
} from '../Shared/Services/AuthService';
import { DatastoreUser } from '../../types/commons';
import { isBDMUser, postEvent, toaster } from '../../services/utils';

enum Fields {
  NAME, PASSWORD, PROFILE_PIC
}

type FieldType = {
  [Fields.NAME]: boolean,
  [Fields.PASSWORD]: boolean,
  [Fields.PROFILE_PIC]: boolean
}

const AccountView: React.FC = () => {
  const { profileInfo, setProfileInfo } = useContext(LearnerContext);
  // stores the temparory user details tampered on input change.
  const [tempProfileInfo, setTempProfileInfo] = useState<DatastoreUser>({} as DatastoreUser);
  const [isFieldEditable, setFieldEditable] = useState<FieldType>({
    [Fields.NAME]: false,
    [Fields.PASSWORD]: false,
    [Fields.PROFILE_PIC]: false,
  });
  const [loading, setLoading] = useState<FieldType>({
    [Fields.NAME]: false,
    [Fields.PASSWORD]: false,
    [Fields.PROFILE_PIC]: false,
  });
  const [disableLogoutCta, setDisableLogoutCta] = useState(false);

  /**
   *
   * sets the profile image source to a dummy avatar
   * @param {*} e
   */
  const handleBrokenImage = (e: any) => { // check type
    e.target.src = '/images/learner/avatar.png';
  };

  /**
   *
   * reverts the field to initial state
   * @param {*} field field to be reset
   */
  const revertFieldInfo = (field: Fields) => {
    if (field in tempProfileInfo) {
      setTempProfileInfo({
        ...tempProfileInfo,
        [field]: profileInfo[field],
      });
    }
  };

  /**
   *
   *
   * @param {*} field
   */
  const toggleEditableField = (field: Fields) => {
    revertFieldInfo(field);
    setFieldEditable({
      ...isFieldEditable,
      [field]: !isFieldEditable[field],
    });
  };

  /**
   *
   *
   * @param {*} field
   */
  const toggleLoadingForField = (field: Fields) => {
    setLoading((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  /**
   *
   * handles input state and sets the state.
   * @param {*} e
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTempProfileInfo({
      ...tempProfileInfo,
      [name]: value,
    });
  };

  /**
   *
   * updates the password in cognito.
   */
  const changePassword = async () => {
    toggleLoadingForField(Fields.PASSWORD);
    const { oldPassword, newPassword } = tempProfileInfo;
    if (validatePasswordFields(oldPassword, newPassword)) {
      const user = await getCurrentUser();
      await updatePassword(user, oldPassword, newPassword);
    }
    toggleLoadingForField(Fields.PASSWORD);
    toggleEditableField(Fields.PASSWORD);
  };

  const triggerIdentifyEvent = async () => {
    const fullName = `${tempProfileInfo?.firstName} ${tempProfileInfo?.lastName}`;
    const eventPayload = {
      userId: profileInfo?.id,
      enterpriseId: profileInfo?.enterpriseID,
      originalTimestamp: dayjs().toISOString(),
      sentAt: dayjs().toISOString(),
      traits: {
        email: profileInfo?.email,
        firstName: tempProfileInfo?.firstName,
        lastName: tempProfileInfo?.lastName,
        name: fullName,
        roles: profileInfo?.roles,
        createdAt: profileInfo?.createdAt,
        lastActive: dayjs().toISOString(),
        exclude: isBDMUser(profileInfo?.email),
      },
    };
    postEvent(eventPayload, '/identify');
  };

  /**
   *
   * updates the name in both cognito
   * and datastore.
   */
  const changeName = async () => {
    toggleLoadingForField(Fields.NAME);
    try {
      if (!tempProfileInfo?.firstName) {
        toaster('Please enter your first name', 'error');
        toggleLoadingForField(Fields.NAME);
        return;
      }
      if (!tempProfileInfo?.lastName) {
        toaster('Please enter your last name', 'error');
        toggleLoadingForField(Fields.NAME);
        return;
      }
      const fullName = `${tempProfileInfo?.firstName} ${tempProfileInfo?.lastName}`;
      const user = await getCurrentUser();
      await updateName(user, { ...tempProfileInfo, name: fullName });
      triggerIdentifyEvent();
      setProfileInfo({
        ...profileInfo,
        name: fullName,
        firstName: tempProfileInfo?.firstName,
        lastName: tempProfileInfo?.lastName,
      });
    } catch {
      // console.log(err);
    }
    toggleEditableField(Fields.NAME);
    toggleLoadingForField(Fields.NAME);
  };

  /**
   *
   * uploads image to s3.
   * @param {*} file
   */
  const uploadImage = async (file: File) => {
    if (!file) {
      return;
    }
    toggleLoadingForField(Fields.PROFILE_PIC);
    try {
      const userId = profileInfo.id;
      await updateUserImage(userId, file);
      setProfileInfo({
        ...profileInfo,
        profilePicture: await Storage.get(userId),
      });
    } catch {
      // console.log(e);
    }
    toggleLoadingForField(Fields.PROFILE_PIC);
  };

  const logoutHandler = () => {
    setDisableLogoutCta(true);
    logout();
  };

  const cancelNameChanges = () => {
    setFieldEditable({
      ...isFieldEditable,
      [Fields.NAME]: !isFieldEditable[Fields.NAME],
    });
    setTempProfileInfo((prevState) => ({
      ...prevState,
      firstName: profileInfo?.firstName,
      lastName: profileInfo?.lastName,
    }));
  };

  useEffect(() => {
    if (profileInfo) {
      setTempProfileInfo(profileInfo);
    }
  }, [profileInfo]);

  return (
    <div className={`${styles.account} library-shaw-icon-new`}>
      <p className={styles.title}>Account & Settings</p>
      <div className={styles.section}>
        <div className={styles.userProfile}>
          {loading[Fields.PROFILE_PIC] && <Spin className={styles.profilePicLoader} />}
          <div className={styles.profilePic}>
            <img
              onError={handleBrokenImage}
              src={`${profileInfo?.profilePicture ? profileInfo?.profilePicture : '/images/learner/avatar.png'}`}
              alt="user-profile"
            />
            <i className="icon-new-Edit1" />
            <label htmlFor="file">
              <input
                id="file"
                accept="image/png, image/jpeg"
                type="file"
                onChange={(e) => e?.target?.files?.[0] && uploadImage(e.target.files[0])}
              />
            </label>
          </div>
          {isFieldEditable[Fields.NAME]
            ? (
              <div className={`${styles.editableField} ${styles.editName}`}>
                <label htmlFor="firstName">
                  First Name*
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={tempProfileInfo?.firstName || ''}
                    placeholder="Enter your first name"
                    onChange={handleChange}
                    className={`${!tempProfileInfo?.firstName ? styles.error : ''}`}
                  />
                </label>
                <label htmlFor="lastName">
                  Last Name*
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={tempProfileInfo?.lastName || ''}
                    placeholder="Enter your last name"
                    onChange={handleChange}
                    className={`${!tempProfileInfo?.lastName ? styles.error : ''}`}
                  />
                </label>
              </div>
            )
            : <p>{profileInfo.name}</p>}
        </div>
        {isFieldEditable[Fields.NAME]
          ? (
            <div className={styles.ctaSection}>
              {loading[Fields.NAME] && <Spin />}
              <button
                id="learner-account-save"
                type="button"
                onClick={changeName}
                disabled={loading[Fields.NAME]}
                className={styles.buttonSave}
              >
                Save
              </button>
              <button
                id="learner-account-cancel-name-changes"
                type="button"
                onClick={cancelNameChanges}
                className={styles.buttonCancel}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              id="learner-account-edit"
              type="button"
              onClick={() => toggleEditableField(Fields.NAME)}
              className={styles.button}
            >
              Edit
            </button>
          )}
      </div>
      <div className={styles.fieldGroup}>
        <div className={styles.fieldTitleSection}>
          <p className={styles.fieldTitle}>Email address</p>
        </div>
        <p className={styles.fieldValue}>{profileInfo?.email}</p>
      </div>
      <div className={styles.fieldGroup}>
        <div className={styles.fieldTitleSection}>
          <p className={styles.fieldTitle}>Password</p>
          {isFieldEditable[Fields.PASSWORD]
            ? (
              <div className={styles.ctaSection}>
                {loading[Fields.PASSWORD] && <Spin />}
                <button
                  id="learner-account-confirm-new-password"
                  type="button"
                  onClick={changePassword}
                  disabled={loading[Fields.PASSWORD]}
                  className={styles.buttonSave}
                >
                  Confirm New Password
                </button>
                <button
                  id="learner-account-cancel-password-changes"
                  type="button"
                  onClick={() => toggleEditableField(Fields.PASSWORD)}
                  className={styles.buttonCancel}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <button
                id="learner-account-change-password"
                type="button"
                onClick={() => toggleEditableField(Fields.PASSWORD)}
                className={styles.button}
              >
                Change password
              </button>
            )}
        </div>
        {isFieldEditable[Fields.PASSWORD]
          ? (
            <div className={styles.editableField}>
              <div className={styles.labelpassword}>
                <div>
                  <span className={styles.labeltext}>Enter your old password</span>
                </div>
                <div>
                  <input
                    type="password"
                    name="oldPassword"
                    placeholder="Enter your old password"
                    onChange={handleChange}
                    className={styles.inputStyle}
                  />
                </div>

              </div>
              <div className={styles.labelpassword}>
                <div>
                  <span className={styles.labeltext}>Enter your new password</span>
                </div>
                <div>
                  <input
                    type="password"
                    name="newPassword"
                    placeholder="Enter your new password"
                    onChange={handleChange}
                    className={styles.inputStyleNew}
                  />
                </div>

                <h6 className={styles.passwordCriteriaView}>
                  Password must be a minimum of 8 characters long.
                </h6>
              </div>
            </div>
          )
          : <p className={styles.fieldTitle}>•••••••••</p>}
      </div>
      <button
        id="learner-account-logout"
        type="button"
        disabled={disableLogoutCta}
        className={styles.logoutCta}
        onClick={logoutHandler}
      >
        Logout
      </button>
    </div>
  );
};
export default AccountView;

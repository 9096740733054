import React, { useEffect } from 'react';
import Layout from '../../components/Learner/Layout';
import Authenticate from '../../components/Identity/Authenticate';
import AccountView from '../../components/Learner/AccountView';
import { viewedPage } from '../../services/TrackActionService';
import { PageKind } from '../../components/Learner/Services/LearnerNavigations';
import { LearnerProvider } from '../../context/Learner/LearnerContext';

/**
 *
 *
 * @return {*}
 */
const Account: React.FC = () => {
  useEffect(() => {
    viewedPage({
      pageKind: PageKind.ACCOUNT,
    });
  }, []);
  return (
    <LearnerProvider>
      <Layout>
        <AccountView />
      </Layout>
    </LearnerProvider>
  );
};

export default Authenticate(Account);
